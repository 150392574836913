<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="ml-2">
        mdi-information-outline
      </v-icon>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Kiosk Information </v-card-title>
      <v-card-text> Kiosk Name: {{ kioskObject.data().name }} </v-card-text>
      <v-card-text>
        Kiosk Location: {{ kioskObject.data().location }}
      </v-card-text>
      <v-card-text> Kiosk Email: {{ kioskObject.data().email }} </v-card-text>
      <v-card-text>
        Kiosk Description: {{ kioskObject.data().description }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "kioskInformation",
  props: {
    kioskObject: Object,
  },
  data() {
    return {
      show: null,
    };
  },
};
</script>