<template>
    <v-dialog
            v-model="show"
            max-width="40%">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    color="primary">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Add New Kiosk
            </v-btn>
        </template>
        <v-card >
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn
                        icon
                        @click="show = !show"
                        class="ma-1" >
                    <v-icon color="red" >mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                Add New Kiosk
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text>
                <v-form
                        ref="addNewKioskForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="kiosk.name"
                            label="Kiosk's Name"
                            :rules="rules.nameRules"
                            required>
                    </v-text-field>
                    <v-text-field
                            v-model="kiosk.location"
                            label="Location"
                            :rules="rules.locationRule"
                            required>
                    </v-text-field>
                    <v-text-field
                            v-model="kiosk.email"
                            label="E-mail"
                            :rules="rules.emailRules"
                            required>
                    </v-text-field>
                    <v-textarea
                            outlined
                            label="Description"
                            @keyup.enter="addNewKiosk"
                            v-model="kiosk.description"
                    ></v-textarea>
                    <v-btn color="primary"
                           dark
                           block
                           large
                           @keyup.enter="addNewKiosk"
                           @click="addNewKiosk">
                        Add and Invite Kiosk
                    </v-btn>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text align="center" >
                <p>Invitation link sent to the kiosk's email will be use to set the password and activate the kiosk.</p>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
  export default {
    name: 'addNewKiosk',
    data(){
      return{
        show: null,
        loading: false,
        kiosk:{
          name: '',
          email: '',
          description: '',
          location: '',
          fdn: window.location.hostname,
        },
        rules:{
          nameRules: [
            v => !!v || 'Kiosk name is required',
          ],
          emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
          locationRule: [
            v => !!v || 'Location is required',
          ]
        }
      }
    },
    methods:{
      addNewKiosk(){
        if(this.$refs.addNewKioskForm.validate()){
          this.loading = true;
          this.$store.dispatch('inviteNewKiosk', this.kiosk)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'green'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
      }

    },
    computed:{

    },
    mounted(){

    }
  }
</script>