<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4 grey--text text--darken-3">
        Kiosk Management
        <v-spacer></v-spacer>
        <addNewKiosk></addNewKiosk>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="px-3 mt-3">
      <v-tabs v-model="tab">
        <v-tab>Registered Kiosks</v-tab>
        <v-tab>Invited Kiosks</v-tab>
        <v-spacer></v-spacer>
        <v-btn class="my-auto mr-2" @click="setViewType('list')" icon>
          <v-icon :color="viewType === 'list'? 'primary' : 'secondary'"> mdi-format-list-bulleted-square </v-icon>
        </v-btn>
        <v-btn class="my-auto mr-2" @click="setViewType('grid')" icon>
          <v-icon :color="viewType !== 'list'? 'primary' : 'secondary'"> mdi-view-grid </v-icon>
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <registeredKioskList :viewType="viewType"></registeredKioskList>
        </v-tab-item>
        <v-tab-item>
          <invitedKioskList ></invitedKioskList>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import addNewKiosk from "@/components/dashboard/admin/kioskManagement/addNewKiosk.vue";
import registeredKioskList from "@/components/dashboard/admin/kioskManagement/registeredKioskList.vue";
import invitedKioskList from "@/components/dashboard/admin/kioskManagement/invitedKioskList.vue";

export default {
  name: "kioskManagement",
  components: {
    addNewKiosk,
    registeredKioskList,
    invitedKioskList,
  },
  data() {
    return {
      tab: null,
      viewType: "list",
    };
  },
  methods:{
    setViewType(type){
      this.viewType = type;
      localStorage.setItem('viewType', this.viewType)

    }
  },
  mounted(){
    this.viewType = localStorage.getItem('viewType');
    
  }
};
</script>