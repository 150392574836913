var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.viewType === 'list')?_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.registeredKiosksHeaders,"items":_vm.allRegisteredKiosks},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().name)+" ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().location)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().email)+" ")]}},{key:"item.last_changed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().last_changed))+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().createdOn))+" ")]}},{key:"item.onlineStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.data().onlineStatus)}},[_vm._v(" "+_vm._s(item.data().onlineStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('editAndDeleteKiosk',{attrs:{"kioskType":"registered","kioskIndex":item}}),_c('kioskConfig',{attrs:{"kioskObject":item}})]}}],null,false,1224980053)}):_c('v-row',{staticClass:"fill-height py-2"},_vm._l((_vm.allRegisteredKiosks),function(kiosk,i){return _c('v-col',{key:i,attrs:{"md":"2","sm":"2","lg":"3","xl":"2","cols":"12"}},[_c('v-card',{staticClass:"cardBorderOffline",class:_vm.getCardClass(kiosk.data().onlineStatus),attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"subtitle-1 mb-n10"},[_vm._v(" "+_vm._s(kiosk.data().name)+" ")]),_c('br'),_c('v-card-actions',{staticClass:"caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm._f("trimLength")(kiosk.data().location))+" ")]),_c('v-card-actions',[_c('span',{staticClass:"caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(kiosk.data().last_changed))+" ")]),_c('v-spacer'),_c('div',[_c('editAndDeleteKiosk',{attrs:{"kioskType":"registered","kioskIndex":kiosk}}),_c('kioskConfig',{attrs:{"kioskObject":kiosk}}),_c('kioskInformation',{attrs:{"kioskObject":kiosk}})],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }